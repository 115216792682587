<template>
  <div class="proFunction">
    <iframe  ref="prints" :src='srcHttp' frameborder="0">
    </iframe>
    <div class="btnDiv"><button class="retBtn" @click="go" style="display: none">返回</button></div>
  </div>
</template>
<script>
import Iframe from "@/components/CommonComponents/Iframe";
export default {
  name: "proFunction",
  components: {Iframe},
  data() {
    return {
      srcHttp: '',
      list: []
    }
  },
  created() {
    let arr =  [
          '',//材料
          'http://spcvp.visumall.com/public/abbeNumber.html',//阿贝数
          'http://spcvp.visumall.com/public/SingleVision.html',//单光
          '',//渐进
          'http://spcvp.visumall.com/public/AntiFatigue.html',//抗疲劳
          'http://spcvp.visumall.com/public/myopiaControl.html',//儿童近视控制
          'http://spcvp.visumall.com/public/DriveEx.html',//驾驶
          'http://spcvp.visumall.com/public/Polarized.html',//偏光 已废弃
          'http://spcvp.visumall.com/public/AntiBlue.html',//防蓝光
          'http://spcvp.visumall.com/public/Drive.html?search=1',//快变色
          'http://spcvp.visumall.com/public/LayerFinger.html',//防尘防静电
          'http://spcvp.visumall.com/public/Layer.html?search=1',//防水
          'http://spcvp.visumall.com/public/Layer.html?search=2',//防雾
          'http://spcvp.visumall.com/public/LayerCanvas.html',//抗磨损
          'http://spcvp.visumall.com/public/Layer.html?search=3',//减反射
          'http://spcvp.visumall.com/public/Drive.html?search=1',//全染色
          'http://spcvp.visumall.com/public/Drive.html?search=1',//渐变色
          'http://spcvp.visumall.com/public/Drive.html?search=1',//驾驶1
          'http://spcvp.visumall.com/public/Drive.html?search=2',//驾驶2
          'http://spcvp.visumall.com/public/sceneEexperience.html',//场景体验 20
        ];
    this.list = arr;
    this.srcHttp = this.list[this.$route.query.proId - 1]
  },
  mounted() {

  },
  methods: {
    go() {
      this.$router.go(-1);
    }
  }
}
</script>
<style scoped lang="scss">
  .proFunction {
    width: 100%;
    height: 76vh;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  .btnDiv {
    width: 100%;
    margin-top: 0;
    display: flex;
    justify-content: center;
  }
  .retBtn {
    padding: 10px 80px;
    background: transparent;
    border: 1px solid #333;
    color: #333;
    font-size: 18px;
  }
</style>
